<template>
  <div id="full">
    <div
      v-if="visivelLogin"
      id="appLogin"
    >
      <img
        :src="image"
        width="100"
        height="150"
        alt="SoftFamily"
      >
    </div>
    <div
      v-if="visivelLogin"
      id="containerxx"
    >
      <v-form @submit.prevent="loginAcessar">
        <v-container>
          <v-alert
            v-if="isMensagem"
            dense
            outlined
            type="error"
          >
            {{ mensagem }}
          </v-alert>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="usuario.email"
                name="email"
                label="Email"
                autofocus
                type="email"
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="usuario.senha"
                :append-icon="isVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isVisible ? 'text' : 'password'"
                name="Senha"
                autocomplete="new-password"
                label="Senha"
                hide-details
                class="input-group--focused"
                @click:append="isVisible = !isVisible"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                style="background-color:#00C853; color:#fff; margin-top:15px;"
                type="submit"
              >
                ENTRAR
              </v-btn>
            </v-col>
          </v-row>
          <hr>
          <v-row
            dense
            class="fontLogin"
          >
            <v-col
              cols="6"
              style="text-align: right; font-size:8pt; padding-top:8px;"
            >
              <span>Não tem conta?</span>
            </v-col>
            <v-col
              cols="6"
              style="text-align: left;"
            >
              <v-btn
                x-small
                rounded
                style="background-color:#FF9800; color:#fff;"
                dark
                @click="visivelLogin = false"
              >
                Criar Conta
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div
      v-else
      class="containerNovoLogin"
    >
      <telaNovoLogin
        :class="{ 'animate__animated animate__fadeInRight': visivelLogin, 'animate__animated animate__fadeInRight': !visivelLogin }"
        @onVoltar="onVoltar"
      />
    </div>
  </div>
</template>
<script>
  import image from '../../../../src/assets/logo-emp.png'
  //   import usuarioRep from '../../dashboard/components/services/loginRepository'
  import firebase from 'firebase'
  import telaNovoLogin from '../pages/transacoes/usuario/novo'
  const db = firebase.firestore()
  export default {
    // validations: {
    //   user: {
    //     email: {
    //       required,
    //     },
    //     password: {
    //       required,
    //     },
    //   },
    // },
    components: {
      telaNovoLogin,
    },
    data () {
      return {
        image: image,
        // bg: bg,
        showDialog: false,
        isLoading: false,
        isVisible: false,
        isMensagem: false,
        mensagem: '',
        usuario: {
          email: '',
          senha: '',
        },
        visivelLogin: true,
        carregarImagem: false,
      }
    },
    created () {
      this.$store.commit('setSplashScreen', false)
      setTimeout(() => {
        this.carregarImagem = true
      }, 500)
    },
    methods: {
      async loginAcessar () {
        this.isLoading = true
        this.$store.commit('setSplashScreen', true)
        firebase.auth().signInWithEmailAndPassword(this.usuario.email, this.usuario.senha).then(response => {
          this.$store.commit('setSplashScreen', false)
          db.collection('usuarios').doc(response.user.uid).get().then(resp => {
            if (resp.exists) {
              var usuario = resp.data()

              this.$store.commit('SET_USUARIO', usuario)
              this.$store.commit('SET_EMAIL', this.usuario.email)
              if (typeof (usuario.uid_empresa) !== 'undefined') {
                db.collection('empresas').doc(usuario.uid_empresa).get().then(async respemp => {
                  if (respemp.exists) {
                    var empresa = respemp.data()
                    this.$store.commit('SET_EMPRESA', empresa)
                    this.$router.replace('/v1')
                  }
                }).catch(() => {
                  this.$swal({
                    position: 'top',
                    icon: 'info',
                    text: 'Usuário não Associado ou Inválido!',
                    timer: 5000,
                    showConfirmButton: true,
                    timerProgressBar: true,
                  })
                })
              } else {
                this.$swal({
                  position: 'top',
                  icon: 'info',
                  text: 'Usuário não Associado!',
                  timer: 5000,
                  showConfirmButton: true,
                  timerProgressBar: true,
                })
              }
            }
          }).catch(() => {
            this.$swal({
              position: 'top',
              icon: 'info',
              text: 'Usuário não Associado ou Inválido!',
              timer: 5000,
              showConfirmButton: true,
              timerProgressBar: true,
            })
          })
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          if (error.code === 'auth/invalid-email') {
            this.$swal({
              position: 'top',
              icon: 'info',
              text: 'Email inválido',
              timer: 5000,
              showConfirmButton: true,
              timerProgressBar: true,
            })
          } else if (error.code === 'auth/user-disabled') {
            this.$swal({
              position: 'top',
              icon: 'info',
              text: 'Email desabilitado',
              timer: 5000,
              showConfirmButton: true,
              timerProgressBar: true,
            })
          } else if (error.code === 'auth/user-not-found') {
            this.$swal({
              position: 'top',
              icon: 'info',
              text: 'Email não existe!',
              timer: 5000,
              showConfirmButton: true,
              timerProgressBar: true,
            })
          } else if (error.code === 'auth/wrong-password') {
            this.$swal({
              position: 'top',
              icon: 'info',
              text: 'Email ou Senha inválido.',
              timer: 5000,
              showConfirmButton: true,
              timerProgressBar: true,
            })
          }
        })
      },
      onVoltar () {
        this.visivelLogin = true
      },
    },
  }
</script>
<style >
#full {
  background-color: #E0E0E0;
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-position: center top; */
  /* margin-top: 15px; */
  font-family: "Red Hat Text", sans-serif !important;
  font-size: 14px !important;
  height: 100%;
  width: 100%;
  position:absolute;
  margin:0;
}
#containerxx {
  background-color: white;
  width: 380px;
  margin: auto;
  padding: 15px;
  margin-top: 15% !important;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: "Red Hat Text", sans-serif !important;
  text-align: center;
  box-shadow: inset 0 0 0.2em #E0E0E0, 0 0 0.3em #546E7A;
}
.containerNovoLogin {
  /* background-color: white; */
  width: 50%;
  margin: auto;
  padding: 15px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: "Red Hat Text", sans-serif !important;
  text-align: center;
  /* box-shadow: inset 0 0 0.2em #E0E0E0, 0 0 0.5em #546E7A; */
  margin-bottom: 10px;
}
.fontLogin {
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 10pt;
}
hr{
  height: 3pt;
  background-color: #00C853;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
#appLogin {
position: absolute;
right: 47.5%;
padding-top:100px;
/* left: 20%; */
/* margin: auto; */
/* padding: 15px; */
}
@media only screen and (min-width: 560px) {
  #full {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  #containerxx {
    margin-top: 30% !important;
  }
  .containerNovoLogin {
    width: 80%;
  }
  #appLogin {
    right: 46%;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 830px) {
  #containerxx {
    margin-top: 30% !important;
  }
  .containerNovoLogin {
    width: 80%;
  }
  #appLogin {
    right: 43%;
    padding-top: 45px;
  }
}
@media only screen and (max-width: 600px) {
  #containerxx {
    margin-top: 35% !important;
  }
  .containerNovoLogin {
    width: 90%;
  }
  #appLogin {
    right: 40%;
  }
}
@media only screen and (max-width: 420px) {
  #full {
    height: 100%;
  }
  #containerxx {
    margin-top: 50% !important;
    width: 100%;
  }
  #appLogin {
    right: 38%;
    padding-top: 15px;
  }
  .containerNovoLogin {
    width: 95%;
  }
}
</style>
